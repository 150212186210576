import React, { useEffect, useState } from "react";
import { Tabs, TabPane } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SingleEventNote from './SingleEventNote';
import AddEventNote from "./EventNotes/AddEventNote";
import { removeEventNote } from "../../../redux/admin/EventActions";
import { getActiveAgentId } from "../../../redux/admin/UserReducer";
import _ from 'lodash';
import BookedVisitsInfo from "./BookedVisitsInfo";

export default function EventNotes({
                                       event_id,
                                       removeVisit,
                                       showMessage,
                                       sendEmailForVisitInfoToUser,
                                       editVisit,
                                       openScheduleVisit,
                                       updateVisit,
                                       updateAssetData,
                                       viewMode,
                                       activeTabState
                                   }) {

  const [activeKey, setActiveKey] = useState(activeTabState || "1"); // Use activeTabState if provided, default to "1"

  const eventNotes = useSelector(state => state.event?.eventNoteMap[event_id]?.eventNoteList);
  const totalCount = useSelector(state => state.event?.eventNoteMap[event_id]?.totalEventNoteCount);
  const isRemovingNote = useSelector(state => state.event.isRemovingNote);
  const isUpdatingNote = useSelector(state => state.event.isUpdatingNote);
  const activeAgentId = useSelector(state => getActiveAgentId(state));
  const totalAgentMap = useSelector(state => state.user.totalAgentMap);

  const [editNote, setNote] = useState({});

  const dispatch = useDispatch();

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleSetEditNote = (note) => {
    setNote(note);
    setActiveKey("3"); // Switch to "Add Note" tab when editing
  };

  const handleRemoveNote = (note_id) => {
    dispatch(removeEventNote(event_id, note_id));
  };

  useEffect(() => {
    if (activeTabState) {
      setActiveKey(activeTabState); // Update active tab on state change
    }
  }, [activeTabState]);

  const items = [
    {
      key: '1',
      label: 'Active Notes',
      children:  <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Note</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {eventNotes?.map(note => (
          <SingleEventNote
            key={note._id}
            note={note}
            isRemovingNote={isRemovingNote}
            isUpdatingNote={isUpdatingNote}
            setEditNote={handleSetEditNote} // Pass handleSetEditNote function
            removeNote={handleRemoveNote}
            activeAgentId={activeAgentId}
            totalAgentMap={totalAgentMap}
          />
        ))}
      </tbody>
    </table>,
    },
    {
      key: '2',
      label: 'Manage Visits',
      children:   <BookedVisitsInfo
      viewMode={viewMode}
      showMessage={showMessage}
      sendEmail={sendEmailForVisitInfoToUser}
      editVisit={editVisit}
      openScheduleVisit={openScheduleVisit}
      removeVisit={removeVisit}
      updateVisit={updateVisit}
      updateAssetData={updateAssetData}
    />,
    },
    {
      key: '3',
      label: 'Add Note',
      children:  <AddEventNote
      event_id={event_id}
      isUpdatingNote={isUpdatingNote}
      editNote={!_.isEmpty(editNote) ? editNote : null}
    />,
    },
  ];

  return (
        <Tabs activeKey={activeKey} onChange={handleTabChange} items={items}/>
    
    
    
  );
}



